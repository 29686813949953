import React from "react";
import NavItem from "./NavItem";

export default function Nav() {
  return (
    <nav className="d-flex flex-column align-items-start p-1">
      <NavItem href="/admin/newshop" text="Shops" />
      <NavItem href="/admin/tags" text="Shop Tags" />
      <NavItem href="/admin/products" text="Shops Menu" />
      <NavItem href="/admin/inactive" text="Inactive Shops" />
      <NavItem href="/admin/alltags" text="All Tags" />
      <NavItem href="/admin/events" text="Events" />
      <NavItem href="/admin/users" text="Users" />
      <NavItem href="/admin/carousel" text="Carousel" />
      <NavItem href="/admin/comments" text="Comments" />
    </nav>
  );
}
