import React, { useState, useEffect } from "react";
import ProductItem from "./ProductItem";

import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Products() {
  const navigate = useNavigate();

  const location = useLocation();
  const selectedShop = location.state.shop;

  const [seletedShopFood, setSelectedShopFood] = useState([]);
  useEffect(() => {
    // setShopsFood
    const formData = new FormData();
    formData.append("shop_id", selectedShop.shopID);
    fetch(
      "https://api.checagocoffee.com/Functions/AdminFetchShopProducts.php",
      {
        method: "POST",
        mode: "cors",
        // No need to set Content-Type header, formData will do that automatically

        // headers: {
        //   "Content-type": "application/json; charset=UTF-8",
        // },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        for (let i = 0; i < json.length; i++) {
          json[i].Product_Rank = json[i].Product_Rank === null ? false : true;
        }
        setSelectedShopFood(json);
      })
      .catch((error) => console.error(error));

    // console.log(shopsInfo);
    // console.log(shopsFood);
  }, [selectedShop]);

  const [products, setProducts] = useState(
    Array(5).fill({
      productName: "",
      productDescription: "",
      productPrice: "",
      productCategory: "",
      productImage: "",
      isTopFood: false,
    })
  );

  const handleInputChange = (index, field, value) => {
    const newProducts = products.map((product, i) => {
      if (i === index) {
        return { ...product, [field]: value };
      }
      return product;
    });
    setProducts(newProducts);
  };

  const handleNewProductSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    let hasValidProducts = false; // Flag to check if there's at least one valid product
    let hasError = false; // Flag to check if there's an error

    // Check each product for required fields
    products.forEach((product, i) => {
      // Check if required fields are filled
      if (
        product.productName &&
        product.productPrice &&
        product.productCategory !== ""
      ) {
        if (!product.productImage && product.isTopFood) {
          toast.error(
            "Please upload an image for top food product or remove the product."
          );
          hasError = true;
          return;
        }
        // Add product to formData only if required fields are filled
        formData.append("shop_id", selectedShop.shopID);
        formData.append(`product${i + 1}_name`, product.productName);
        formData.append(
          `product${i + 1}_description`,
          product.productDescription
        );
        formData.append(`product${i + 1}_image`, product.productImage);
        formData.append(`product${i + 1}_price`, product.productPrice);
        formData.append(`product${i + 1}_sub_cat`, product.productCategory);
        formData.append(`product${i + 1}_is_top_food`, product.isTopFood);
        hasValidProducts = true; // Set flag to true since we have at least one valid product
      }
    });

    // Proceed only if there is at least one valid product
    if (hasError) {
      return;
    }
    if (hasValidProducts) {
      fetch("https://api.checagocoffee.com/Functions/AdminAddShopProduct.php", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.text())
        .then((json) => {
          console.log(json);
          toast.success("Form submitted successfully!");
          setProducts(
            Array(5).fill({
              productName: "",
              productDescription: "",
              productPrice: "",
              productCategory: "",
              productImage: "",
              isTopFood: false,
            })
          );
          window.location.reload();
        });
    } else {
      // If no products are valid, show a message
      toast.error("Please fill at least one product with all required fields.");
    }
  };

  return (
    <div className="container-xl mt-5">
      <h1>Shops Menu</h1>

      <div>
        <div className="products row d-flex align-items-center justify-content-center mx-auto">
          <button
            type="button"
            className="col-md-1 btn btn-outline-secondary mb-3"
            onClick={() => navigate("/admin/products")}
          >
            Back
          </button>
          <h3 className="col-md-4 mb-3 ms-3">Shop: {selectedShop.shopName}</h3>
          <h4 className="text-center">Add New Food Item</h4>
          <form
            onSubmit={handleNewProductSubmit}
            encType="multipart/form-data"
            method="post"
            className="my-3"
          >
            <div className="products-form row">
              {products.map((product, index) => (
                <div key={index} className="mb-3 row">
                  <h5>Food Item {index + 1}</h5>
                  <div className="mb-3 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Food Name"
                      value={product.productName}
                      onChange={(e) =>
                        handleInputChange(index, "productName", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <textarea
                      className="form-control"
                      placeholder="Description"
                      rows="1"
                      value={product.productDescription}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "productDescription",
                          e.target.value
                        )
                      }
                      style={{ height: "auto" }}
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Price"
                      value={product.productPrice}
                      onChange={(e) =>
                        handleInputChange(index, "productPrice", e.target.value)
                      }
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <select
                      className="form-control"
                      name="productCategory"
                      value={product.productCategory.toString()}
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "productCategory",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select Category</option>
                      <option value="1">Drinks</option>
                      <option value="13">Food</option>
                    </select>
                  </div>

                  <div className="mb-3 col-md-4">
                    <input
                      type="file"
                      className="form-control"
                      name="productImage"
                      accept="image/*"
                      onChange={(e) =>
                        handleInputChange(
                          index,
                          "productImage",
                          e.target.files[0]
                        )
                      }
                    />
                  </div>

                  <div className="mb-3 col-md-4">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      name="isTopFood"
                      checked={product.isTopFood}
                      onChange={(e) =>
                        handleInputChange(index, "isTopFood", e.target.checked)
                      }
                    />
                    <label className="form-check-label ms-2">Is Top Food</label>
                  </div>
                </div>
              ))}
              <button
                type="submit"
                className="btn btn-outline-primary row col-6 mx-auto justify-content-center"
                onClick={handleNewProductSubmit}
              >
                Submit All Products
              </button>
            </div>
          </form>
        </div>
        <div className="row col-8 mx-auto justify-content-center">
          <h4 className="my-3 text-center">Current Shop Menu</h4>
          {seletedShopFood.map((food) => (
            <div key={food.Product_ID}>
              <ProductItem food={food} index={food.Product_ID} />
            </div>
          ))}
        </div>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
