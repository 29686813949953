import React, { useState } from "react";
import "./EditableText.css";

function EditableText({ className = "", initialText, onSave, onDelete }) {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(initialText);

  const toggleEdit = (e) => {
    e.preventDefault();
    if (isEditing) {
      onSave(text);
    }
    setIsEditing(!isEditing);
  };

  const handleChange = (e) => {
    setText(e.target.value);
  };

  return (
    <div className={`${className} editable-container`}>
      {isEditing ? (
        <input
          type="text"
          value={text}
          onChange={handleChange}
          autoFocus
          className="editable-text-input"
        />
      ) : (
        <span onClick={toggleEdit} className="editable-text-span">
          {text}
        </span>
      )}
      <button
        className="btn btn-outline-primary btn-sm me-2"
        onClick={toggleEdit}
      >
        {isEditing ? "Save" : "Edit"}
      </button>

      <button className="btn btn-outline-danger btn-sm" onClick={onDelete}>
        Delete
      </button>
    </div>
  );
}

export default EditableText;
