import "bootstrap/dist/css/bootstrap.min.css";
import "./Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "./auth/AuthProvider";

function Login() {
  const [selectLogin, setSelectLogin] = useState(false);
  const [admin_username, setAdminUsername] = useState("");
  const [admin_password, setAdminPassword] = useState("");

  // useNavigate is a hook that allows us to navigate to a different route
  const navigate = useNavigate();
  // useAuth is a hook that gives us access to the login and logout functions
  const { login } = useAuth();

  const loginButtonLogic = (e) => {
    e.preventDefault();
    console.log("This is username: " + admin_username);
    console.log("This is password: " + admin_password);

    fetch("https://api.checagocoffee.com/Functions/AdminLogin.php", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify({
        admin_username: admin_username,
        admin_password: admin_password,
      }),
    })
      //
      // "mode: no-cors" disables reading the response.
      // uncommenting the line below will cause the browser to give a syntax error,
      // "unexpected end of input".
      //
      .then((response) => response.json())
      .then((json) => {
        //{status: "success", message: "Login successful"}
        console.log(json.message);
        if (json.status === "success") {
          console.log("Login successful");
          // Call login to set isAuthenticated to true
          login();
          // Use navigate to visit the /admin route
          navigate("/admin");
        } else {
          console.log("Login failed");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <>
      {!selectLogin ? (
        <div className="container-xl d-flex flex-column justify-content-center align-items-center vh-100">
          <h5 className="text-center mt-3 mb-3">
            Hey friends, if you are fans of Checago Coffee, please visit{" "}
            <a
              href="https://checagocoffee.com"
              style={{
                color: "blue",
              }}
            >
              Checago Coffee
            </a>{" "}
            to show your support!
          </h5>
          <h5 className="text-center mt-3 mb-3">
            If you are the administrators of Checago Coffee, please{" "}
            <span
              style={{
                color: "blue",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() => setSelectLogin(true)}
            >
              log in
            </span>{" "}
            here.
          </h5>
        </div>
      ) : (
        <div className="login_page">
          <div className="container">
            <div className="contents">
              <h1 className="login_title">Checago Coffee Admin</h1>
              <input
                name="admin_username"
                type="text"
                className="form-control login_username"
                value={admin_username}
                onChange={(e) => setAdminUsername(e.target.value)}
                placeholder="Username"
                aria-describedby="inputGroup-sizing-default"
              />
              <input
                name="admin_password"
                type="password"
                className="form-control login_password"
                value={admin_password}
                onChange={(e) => setAdminPassword(e.target.value)}
                placeholder="Password"
                aria-describedby="inputGroup-sizing-default"
              />
              <button
                onClick={loginButtonLogic}
                type="button"
                className="btn btn-warning login_button"
              >
                Login
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Login;
