import React, { useState, useEffect } from "react";
import "./Tags.css";

import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Tags() {
  const navigate = useNavigate();
  const location = useLocation();
  const selectedShop = location.state.shop;

  const [shopTags, setShopTags] = useState([]);
  const [modifiedItems, setModifiedItems] = useState([]);
  useEffect(() => {
    // fetch shopTags from backend
    const formData = new FormData();
    formData.append("shop_id", selectedShop.shopID);

    fetch("https://api.checagocoffee.com/Functions/AdminRetrieveShopTag.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const contentLength = response.headers.get("Content-Length");
        if (contentLength === "0") {
          console.log("No shopTags found for this shop");
          return [];
        }
        return response.json();
      })
      .then((json) => {
        setShopTags(json);
      })
      .catch((error) => console.error(error));
  }, [selectedShop.shopID]);

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const tagID = parseInt(id);
    setShopTags((prev) =>
      prev.map((tag) => ({
        ...tag,
        Shop_ID:
          tag.Tag_ID === tagID
            ? checked
              ? selectedShop.shopID
              : null
            : tag.Shop_ID,
      }))
    );

    if (modifiedItems.find((item) => item.Tag_ID === tagID)) {
      setModifiedItems((prev) => prev.filter((item) => item.Tag_ID !== tagID));
    } else {
      setModifiedItems((prev) => [
        ...prev,
        {
          Shop_ID: selectedShop.shopID,
          Tag_ID: tagID,
          Selected: checked,
        },
      ]);
    }
  };

  const handleTagsSubmit = (e) => {
    e.preventDefault();
    if (modifiedItems.length > 0) {
      console.log(modifiedItems);
      // TODO: send edited shopTags to backend
      modifiedItems.forEach((item) => {
        const formData = new FormData();
        formData.append("shop_id", item.Shop_ID);
        formData.append("tag_id", item.Tag_ID);
        if (item.Selected === false) {
          fetch(
            "https://api.checagocoffee.com/Functions/AdminDeleteShopTag.php",
            {
              method: "POST",
              mode: "cors",
              body: formData,
            }
          )
            .then((response) => response.json())
            .then((json) => {
              // console.log(json);
            })
            .catch((error) => console.error(error));
        } else {
          fetch("https://api.checagocoffee.com/Functions/AdminAddShopTag.php", {
            method: "POST",
            mode: "cors",
            body: formData,
          })
            .then((response) => response.json())
            .then((json) => {
              // console.log(json);
            })
            .catch((error) => console.error(error));
        }
      });
      setModifiedItems([]);

      toast.success("Edited successfully!");
    }
  };

  const [newTag, setNewTag] = useState("");

  const handleAddTag = () => {
    if (newTag && !shopTags.includes(newTag)) {
      // TODO: send new tag to backend
      const formData = new FormData();
      formData.append("tag_name", newTag);

      fetch("https://api.checagocoffee.com/Functions/AdminAddNewTag.php", {
        method: "POST",
        mode: "cors",
        body: formData,
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setShopTags([
            ...shopTags,
            {
              Shop_ID: selectedShop.shopID,
              Tag_ID: parseInt(json.tag_id),
              Tag_Name: newTag,
            },
          ]); // Add new tag to the list after successful addition
        })
        .catch((error) => console.error(error));

      console.log(newTag);

      setNewTag(""); // Reset new tag input after adding
    }
  };

  return (
    <div className="container-xl mt-5">
      <h1>Tags</h1>
      <div>
        <form onSubmit={handleTagsSubmit} className="mb-3">
          <div className="shop-shopTags row d-flex align-items-center justify-content-center mx-auto">
            <button
              type="button"
              className="col-md-1 btn btn-outline-secondary mb-3"
              onClick={() => navigate("/admin/tags")}
            >
              Back
            </button>
            <h3 className="col-md-4 mb-3 ms-3">
              Shop: {selectedShop.shopName}
            </h3>

            <div className="tag-input-container my-3 row d-flex align-items-center justify-content-center mx-auto">
              <div className="col-6 col-sm-6 col-md-3">
                <input
                  type="text"
                  value={newTag}
                  onChange={(e) => setNewTag(e.target.value)}
                  placeholder="Add new tag"
                />
              </div>
              <div className="col-6 col-sm-6 col-md-3">
                <button
                  type="button"
                  onClick={handleAddTag}
                  className="btn btn-outline-secondary ms-2"
                >
                  Add Tag
                </button>
              </div>
            </div>

            <div className="mb-3 row col-6 d-flex align-items-center justify-content-center mx-auto">
              {shopTags.map((tag) => (
                <div key={tag.Tag_Name}>
                  <input
                    type="checkbox"
                    id={tag.Tag_ID}
                    name="shopTags"
                    value={tag.Tag_Name}
                    checked={tag.Shop_ID === selectedShop.shopID}
                    onChange={handleCheckboxChange}
                  />
                  <label htmlFor={tag.Tag_ID} className="ms-2 mb-2">
                    {tag.Tag_Name}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <div className="row col-6 mx-auto">
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
          </div>
        </form>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
