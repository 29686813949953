import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./auth/AuthProvider";
import ProtectedRoute from "./auth/ProtectedRoute";
import Header from "./navBar/Header";
import Admin from "./Admin";
import Login from "./Login";
import UploadAndEditShop from "./shops/UploadAndEditShop";
import Tags from "./shops/Tags";
import Products from "./shops/Products";
import InactiveShop from "./shops/InactiveShop";
import AllTags from "./tags/AllTags";
import ShopTable from "./components/ShopTable";
import Events from "./events/Events";
import Users from "./users/User";
import Comments from "./comments/Comment";
import Carousel from "./carousel/Carousel";
import "./App.css";
import { useState, useEffect } from "react";

export default function App() {
  // the root component of our application
  // define the overall structure and composition of your app
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const handleResize = () => {
    if (window.innerWidth > 575) {
      setIsSidebarVisible(true);
    } else if (window.innerWidth <= 575) {
      setIsSidebarVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <AuthProvider>
      <Router>
        <div className="container-fluid">
          <div className="row">
            <ProtectedRoute>
              {isSidebarVisible && (
                <div
                  className="col-12 col-sm-3 col-md-2 bg-dark sidebar sticky-top"
                  id="sidebar"
                  onClick={() => {
                    if (window.innerWidth <= 575) {
                      setIsSidebarVisible(false);
                    }
                  }}
                >
                  <Header />
                </div>
              )}
            </ProtectedRoute>

            <div className="col-12 col-sm-9 col-md-10 offset-md-2 ms-md-auto px-md-4">
              <div
                className="d-flex flex-column align-items-stretch flex-shrink-0 bg-white"
                style={{ height: "100vh", overflowY: "auto" }}
              >
                {!isSidebarVisible && (
                  <ProtectedRoute>
                    <button
                      className="btn btn-outline-secondary show-sidebar-button"
                      onClick={() => setIsSidebarVisible(true)}
                    >
                      Show Sidebar
                    </button>
                  </ProtectedRoute>
                )}
                <Routes>
                  <Route
                    path="/"
                    element={
                      <div id="login_page">
                        <Login />
                      </div>
                    }
                  />
                  <Route
                    path="/admin"
                    element={
                      <ProtectedRoute>
                        <Admin />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/newshop"
                    element={
                      <ProtectedRoute>
                        <UploadAndEditShop />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/tags"
                    element={
                      <ProtectedRoute>
                        <ShopTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/tags/shop/:shopID"
                    element={
                      <ProtectedRoute>
                        <Tags />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/products"
                    element={
                      <ProtectedRoute>
                        <ShopTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/products/shop/:shopID"
                    element={
                      <ProtectedRoute>
                        <Products />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/topfood"
                    element={
                      <ProtectedRoute>
                        <ShopTable />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/inactive"
                    element={
                      <ProtectedRoute>
                        <InactiveShop />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/alltags"
                    element={
                      <ProtectedRoute>
                        <AllTags />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/events"
                    element={
                      <ProtectedRoute>
                        <Events />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/users"
                    element={
                      <ProtectedRoute>
                        <Users />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/comments"
                    element={
                      <ProtectedRoute>
                        <Comments />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path="/admin/carousel"
                    element={
                      <ProtectedRoute>
                        <Carousel />
                      </ProtectedRoute>
                    }
                  />
                  {/* Other Routes as needed */}
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </Router>
    </AuthProvider>
  );
}
