import React, { useState } from "react";

export default function ProductItemEditor({ onClose, food, onSave }) {
  const [product, setProduct] = useState(food);

  const handleEditProductSubmit = (e) => {
    e.preventDefault();
    onSave({ product });
    onClose(); // Close modal after save
  };

  const handleInputChange = (key, value) => {
    setProduct((prevProduct) => ({
      ...prevProduct,
      [key]: value,
    }));
  };

  return (
    <div>
      <button
        type="button"
        className="mt-3 btn-close"
        onClick={onClose}
      ></button>
      <form
        onSubmit={handleEditProductSubmit}
        encType="multipart/form-data"
        method="post"
        className="my-3 ms-3"
      >
        <div className="products-form row">
          <div className="mb-3 row">
            <div className="mb-3 col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Food Name"
                value={product.Product_Name}
                onChange={(e) =>
                  handleInputChange("Product_Name", e.target.value)
                }
              />
            </div>

            <div className="mb-3 col-6">
              <textarea
                className="form-control"
                placeholder="Description"
                rows="1"
                value={product.Product_Description}
                onChange={(e) =>
                  handleInputChange("Product_Description", e.target.value)
                }
                style={{ height: "auto" }}
              />
            </div>

            <div className="mb-3 col-6">
              <input
                type="text"
                className="form-control"
                placeholder="Price"
                value={product.Product_Price}
                onChange={(e) =>
                  handleInputChange("Product_Price", e.target.value)
                }
              />
            </div>

            <div className="mb-3 col-6">
              <select
                className="form-control"
                name="Sub_Cat_ID"
                value={product.Sub_Cat_ID === 1 ? "1" : "13"}
                onChange={(e) =>
                  handleInputChange("Sub_Cat_ID", parseInt(e.target.value))
                }
              >
                <option value="1">Drinks</option>
                <option value="13">Food</option>
              </select>
            </div>

            <div className="mb-3 col-6">
              <input
                type="file"
                className="form-control"
                name="Product_Image"
                accept="image/*"
                onChange={(e) =>
                  handleInputChange("Product_Image", e.target.files[0])
                }
              />
            </div>
            <div className="mb-3 col-6">
              <input
                type="checkbox"
                className="form-check-input"
                name="Product_Rank"
                checked={product.Product_Rank}
                onChange={(e) =>
                  handleInputChange("Product_Rank", e.target.checked)
                }
              />
              <label className="form-check-label ms-2">Is Top Food</label>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-primary row col-6 mx-auto justify-content-center"
            onClick={handleEditProductSubmit}
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
}
