import "bootstrap/dist/css/bootstrap.min.css";
import "./User.css";
import React, { useState, useEffect } from "react";
import { useRef } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function Users() {
  useEffect(() => {
    fetch(
      "https://api.checagocoffee.com/Functions/AdminRetrieveUserProfile.php",
      {
        method: "GET",
        mode: "cors",
      }
    )
      .then((response) => response.json())
      .then((json) => {
        setFetchedUser(json);
      })
      .catch((error) => console.error(error));
  }, []);

  const [fetchedUser, setFetchedUser] = useState([]);
  const [usernameInput, setUsernameInput] = useState("");
  const [emailInput, setEmailInput] = useState("");
  const [imageInput, setImageInput] = useState(null);
  const [selectedUser, setSelectedUser] = useState({});

  // Ref object to reference the input element
  const inputFile = useRef(null);

  const editClick = (user) => {
    // This is to clear out the image input on user's end
    if (inputFile.current) {
      inputFile.current.value = "";
      inputFile.current.type = "text";
      inputFile.current.type = "file";

      setImageInput("");
    }

    setSelectedUser(user);
    setUsernameInput(user.User_Name);
    setEmailInput(user.User_Email);
  };

  const deleteClick = (user) => {
    console.log("Deleted on " + user.User_ID);

    const formData = new FormData();
    formData.append("user_id", user.User_ID);
    formData.append("user_image", user.User_Image);

    fetch(
      "https://api.checagocoffee.com/Functions/AdminDeleteUserProfile.php",
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json.message);

        setFetchedUser((prev) =>
          prev.filter((users) => users.User_ID !== user.User_ID)
        );
      })
      .catch((error) => console.error(error));
  };

  const inputFormSubmit = (e) => {
    e.preventDefault();

    console.log("Inputted username: " + usernameInput);
    console.log("Inputted email: " + emailInput);
    console.log(imageInput);

    const formData = new FormData();
    formData.append("user_id", selectedUser.User_ID);
    formData.append("user_name", usernameInput);
    formData.append("user_email", emailInput);
    formData.append("user_image", imageInput);

    fetch(
      "https://api.checagocoffee.com/Functions/AdminUpdateUserProfile.php",
      {
        method: "POST",
        mode: "cors",
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((json) => {
        console.log(json.message);
        selectedUser.User_Image = json.message;
        setFetchedUser((prev) =>
          prev.map((users) => {
            if (users.User_ID === selectedUser.User_ID) {
              users.User_Name = usernameInput;
              users.User_Email = emailInput;
              users.User_Image = json.message;
              return users;
            } else {
              return users;
            }
          })
        );
        setEmailInput("");
        setUsernameInput("");
        setImageInput(null);
        setSelectedUser({});
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="users">
      <div className="d-flex align-items-center justify-content-center">
        <div className="users-input-form">
          <form
            onSubmit={inputFormSubmit}
            encType="multipart/form-data"
            method="post"
          >
            <input
              className="form-control username_input"
              type="text"
              placeholder="Username"
              onChange={(e) => {
                setUsernameInput(e.target.value);
              }}
              value={usernameInput}
            />
            <input
              className="form-control email_input"
              type="text"
              placeholder="Email"
              onChange={(e) => {
                setEmailInput(e.target.value);
              }}
              value={emailInput}
            />

            <input
              className="form-control image_input"
              type="file"
              name="image"
              accept="image/*"
              onChange={(e) => {
                setImageInput(e.target.files[0]);
              }}
              ref={inputFile}
            />
            <button
              type="submit"
              className="btn btn-outline-dark submit_button"
            >
              Submit
            </button>
          </form>
        </div>
      </div>

      <div className="users-table">
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: "30%", textAlign: "center" }}>Username</th>
              <th style={{ width: "30%", textAlign: "center" }}>Email</th>
              <th style={{ width: "25%", textAlign: "center" }}>Image</th>
              <th style={{ width: "15%" }}></th>
            </tr>
          </thead>
          <tbody>
            {fetchedUser.map((item, index) => (
              <tr key={index}>
                <td>{item.User_Name}</td>
                <td>{item.User_Email}</td>
                <td>
                  <LazyLoadImage
                    height={55}
                    src={
                      "https://api.checagocoffee.com/profileImages/" +
                      item.User_Image
                    }
                    alt=""
                  />
                </td>
                <td>
                  <button
                    onClick={() => editClick(item)}
                    className="btn btn-outline-dark submit_button"
                  >
                    Edit
                  </button>
                  <button
                    onClick={() => deleteClick(item)}
                    className="btn btn-outline-danger"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
