import React, { useState, useEffect } from "react";

export default function Carousel() {
  const [carouselItems, setCarouselItems] = useState([]);

  // Fetch all Carousel items
  useEffect(() => {
    fetch("https://api.checagocoffee.com/Functions/AdminRetrieveCarousel.php", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setCarouselItems(json);
      })
      .catch((error) => console.error(error));
  }, []);

  // Delete a carousel item
  const deleteCarouselItem = (e, item) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("carousel_id", item.Carousel_ID);

    fetch("https://api.checagocoffee.com/Functions/AdminDeleteCarousel.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setCarouselItems((prev) =>
          prev.filter((cur) => cur.Carousel_ID !== item.Carousel_ID)
        );
      })
      .catch((error) => console.error(error));
  };

  const [newItem, setNewItem] = useState(false);
  const [shopsInfo, setShopsInfo] = useState([]);
  const getShops = () => {
    setNewItem(true);
    fetch(
      "https://api.checagocoffee.com/Functions/AdminFetchShopsWithoutHours.php",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setShopsInfo(json);
      })
      .catch((error) => console.error(error));
  };

  const [newItemShop, setNewItemShop] = useState(false);
  const [selectedShop, setSelectedShop] = useState(null);

  const [seletedShopFood, setSelectedShopFood] = useState([]);

  const getMenu = (item) => {
    setNewItemShop(true);
    setSelectedShop(item);
    fetch(
      "https://api.checagocoffee.com/Functions/AdminFetchShopProducts.php",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setSelectedShopFood(
          json.filter((food) => food.Shop_ID === item.shopID)
        );
      })
      .catch((error) => console.error(error));
  };

  const submitNewItem = (item) => {
    const formData = new FormData();
    formData.append("product_id", item.Product_ID);
    formData.append("product_image", item.Product_Image);
    formData.append("product_name", item.Product_Name);
    formData.append("shop_id", item.Shop_ID);
    fetch("https://api.checagocoffee.com/Functions/AdminAddCarousel.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        json.Shop_Name = selectedShop.shopName;
        setCarouselItems((prev) => [...prev, json]);
        setNewItem(false);
        setNewItemShop(false);
        setSelectedShop(null);
      });
  };

  return (
    <div className="container-xl mt-5">
      <h1>Carousel</h1>

      <button
        type="button"
        className="btn btn-outline-primary mb-3"
        onClick={getShops}
      >
        Add New Carousel Item
      </button>
      {newItem && !newItemShop ? (
        <>
          <div className="row mb-3">
            <div className="col-md-1">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setNewItem(false)}
              >
                Back
              </button>
            </div>
            <div className="col-md-11">
              <h2>Select a shop for the new carousel item</h2>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Shop Name</th>
                <th>Shop Description</th>
                <th>Shop Address</th>
                <th>Image</th>
                <th>Shop Website</th>
                <th>isActive</th>
              </tr>
            </thead>
            <tbody>
              {shopsInfo.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => getMenu(item)}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ display: "none" }}>{item.shopID}</td>
                  <td>{item.shopName}</td>
                  <td>{item.shopDescription}</td>
                  <td>{item.shopAddress}</td>
                  <td>
                    <img
                      src={"https://api.checagocoffee.com/shops/" + item.Image}
                      alt={item.shopName}
                      style={{ width: "100px", height: "auto" }}
                    />
                  </td>
                  <td>{item.shopWebsite}</td>
                  <td>{item.isActive ? "Active" : "Inactive"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}

      {newItem && newItemShop ? (
        <>
          <div className="row mb-3">
            <div className="col-md-1">
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => setNewItemShop(false)}
              >
                Back
              </button>
            </div>
            <div className="col-md-11">
              <h2>Select a product item for the new carousel item</h2>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th>Product Name</th>
                <th>Product Description</th>
                <th>Image</th>
                <th>Category</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              {seletedShopFood.map((item, index) => (
                <tr
                  key={index}
                  onClick={() => submitNewItem(item)}
                  style={{ cursor: "pointer" }}
                >
                  <td style={{ display: "none" }}>{item.Product_ID}</td>
                  <td>{item.Product_Name}</td>
                  <td>{item.Product_Description}</td>
                  <td>
                    <img
                      src={
                        "https://api.checagocoffee.com/product/" +
                        item.Product_Image
                      }
                      alt={item.Product_Name}
                      style={{ width: "100px", height: "auto" }}
                    />
                  </td>
                  <td>{item.Sub_Cat_ID === 1 ? "Drink" : "Food"}</td>
                  <td>{item.Product_Price}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      ) : null}
      <table className="table">
        <thead>
          <tr>
            <th>Shop Name</th>
            <th>Product Name</th>
            <th>Product Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {carouselItems.map((item, index) => (
            <tr key={index} style={{ cursor: "pointer" }}>
              <td style={{ display: "none" }}>{item.Carousel_ID}</td>
              <td>{item.Shop_Name}</td>
              <td>{item.Product_Name}</td>
              <td>
                <img
                  src={
                    "https://api.checagocoffee.com/product/" +
                    item.Product_Image
                  }
                  alt={item.Product_Name}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>
                <button
                  className="btn btn-outline-danger btn-sm"
                  onClick={(e) => deleteCarouselItem(e, item)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
