import React, { useState, useEffect } from "react";
import EditableText from "../components/EditableText";

export default function AllTags() {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    console.log("fetching tags...");
    // setTags
    fetch("https://api.checagocoffee.com/Functions/RetrieveTags.php", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        setTags(json);
      })
      .catch((error) => console.error(error));
  }, []);
  const [newTag, setNewTag] = useState("");
  const handleAddTag = () => {
    if (newTag && !tags.includes(newTag)) {
      // TODO: send new tag to backend

      const formData = new FormData();
      formData.append("tag_name", newTag);

      fetch("https://api.checagocoffee.com/Functions/AdminAddNewTag.php", {
        method: "POST",
        mode: "cors",
        // No need to set Content-Type header, formData will do that automatically

        // headers: {
        //   "Content-type": "application/json; charset=UTF-8",
        // },
        body: formData,
      })
        .then((response) => response.json())
        .then((json) => {
          console.log(json);
          setTags([
            ...tags,
            { Tag_ID: parseInt(json.tag_id), Tag_Name: newTag },
          ]);
        })
        .catch((error) => console.error(error));

      setNewTag(""); // Reset new tag input after adding
    }
  };

  const handleEditTag = (tag, newText) => {
    console.log(tag);
    console.log(newText);

    const formData = new FormData();
    formData.append("old_tag_name", tag.Tag_Name);
    formData.append("new_tag_name", newText);

    fetch("https://api.checagocoffee.com/Functions/AdminEditTag.php", {
      method: "POST",
      mode: "cors",
      // No need to set Content-Type header, formData will do that automatically

      // headers: {
      //   "Content-type": "application/json; charset=UTF-8",
      // },
      body: formData,
    })
      .then((response) => response.text())
      .then((text) => {
        console.log(text);
      })
      .catch((error) => console.error(error));
  };

  const handleDeleteTag = (tag) => {
    setTags(tags.filter((t) => t !== tag));
    console.log(tag);

    const formData = new FormData();
    formData.append("tag_id", tag.Tag_ID);

    fetch("https://api.checagocoffee.com/Functions/AdminDeleteTag.php", {
      method: "POST",
      mode: "cors",
      // No need to set Content-Type header, formData will do that automatically

      // headers: {
      //   "Content-type": "application/json; charset=UTF-8",
      // },
      body: formData,
    })
      .then((response) => response.text())
      .then((text) => {
        console.log(text);
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="container-xl mt-5">
      <h1>All Tags</h1>
      <div className="row my-3 align-items-center justify-content-center">
        <div className="col-md-4">
          <input
            type="text"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
            placeholder="Add new tag"
            className="form-control"
          />
        </div>
        <div className="col-md-2">
          <button
            type="button"
            onClick={handleAddTag}
            className="btn btn-primary w-100"
          >
            Add Tag
          </button>
        </div>
      </div>
      <div className="row my-3">
        {tags.map((tag) => (
          <div key={tag.Tag_Name} className="col-6 my-2">
            <div className="d-flex justify-content-between align-items-center p-3 border rounded">
              <EditableText
                className="col-12"
                initialText={tag.Tag_Name}
                onSave={(newText) => handleEditTag(tag, newText)}
                onDelete={() => handleDeleteTag(tag)}
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
