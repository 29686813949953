import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

export default function ShopTable() {
  const [shopsInfo, setShopsInfo] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    fetch(
      "https://api.checagocoffee.com/Functions/AdminFetchShopsWithoutHours.php",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setShopsInfo(json);
      })
      .catch((error) => console.error(error));
  }, []);

  let catID = location.pathname.split("/").pop();
  if (catID === "products") {
    catID = "Shops Menu";
  } else if (catID === "tags") {
    catID = "Tags";
  }
  return (
    <div className="table-responsive container-xl mt-5">
      <h1>{catID}</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Shop Name</th>
            <th>Shop Description</th>
            <th>Shop Address</th>
            <th>Image</th>
            <th>Shop Website</th>
            <th>IsActive</th>
          </tr>
        </thead>
        <tbody>
          {shopsInfo.map((item, index) => (
            <tr
              key={index}
              onClick={() =>
                navigate(`${location.pathname}/shop/${item.shopID}`, {
                  state: { shop: item },
                })
              }
              style={{ cursor: "pointer" }}
            >
              <td style={{ display: "none" }}>{item.shopID}</td>
              <td>{item.shopName}</td>
              <td>{item.shopDescription}</td>
              <td>{item.shopAddress}</td>
              <td>
                <img
                  src={"https://api.checagocoffee.com/shops/" + item.Image}
                  alt={item.shopName}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>{item.shopWebsite}</td>
              <td>{item.isActive ? "Active" : "Inactive"}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
