import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function InactiveShop() {
  const [shopsInfo, setShopsInfo] = useState([]);

  useEffect(() => {
    fetch(
      "https://api.checagocoffee.com/Functions/AdminFetchInactiveShop.php",
      {
        method: "GET",
        mode: "cors",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
        },
      }
    )
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        setShopsInfo(json);
      })
      .catch((error) => console.error(error));
  }, []);

  const activateShop = (e, item) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("shop_id", item.Shop_ID);
    fetch("https://api.checagocoffee.com/Functions/AdminActivateShop.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        // echo json_encode(["status" => "success", "message" => "Shop activated successfully"]);
        if (json.status === "success") {
          toast.success("Shop activated");
          setShopsInfo((prev) =>
            prev.filter((shop) => shop.Shop_ID !== item.Shop_ID)
          );
        } else {
          toast.error("Shop not activated");
        }
      })
      .catch((error) => console.error(error));
  };
  return (
    <div className="table-responsive container-xl mt-5">
      <h1>Inactive Shops</h1>
      <table className="table">
        <thead>
          <tr>
            <th>Shop Name</th>
            <th>Shop Description</th>
            <th>Shop Address</th>
            <th>Image</th>
            <th>Shop Website</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {shopsInfo.map((item, index) => (
            <tr key={index} style={{ cursor: "pointer" }}>
              <td style={{ display: "none" }}>{item.Shop_ID}</td>
              <td>{item.Shop_Name}</td>
              <td>{item.Shop_Description}</td>
              <td>{item.Shop_Address}</td>
              <td>
                <img
                  src={"https://api.checagocoffee.com/shops/" + item.Image}
                  alt={item.Shop_Name}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>{item.Shop_Website}</td>
              <td>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    activateShop(e, item);
                  }}
                >
                  Activate
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
