import React, { useState, useEffect, useRef } from "react";
import ProductItemEditor from "./ProductItemEditor";

export default function ProductItem({ food, index }) {
  const [curFood, setFood] = useState(food); // Ensure initialFood is always defined
  const [showEditor, setShowEditor] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  const editorRef = useRef();
  useEffect(() => {
    if (showEditor) {
      editorRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [showEditor]);

  const editFood = (e) => {
    e.preventDefault();
    setShowEditor(true);
  };

  const deleteFood = (e) => {
    e.preventDefault();
    setIsDeleted(true);
    // Delete food from backend
    //$product_id = $_POST['product_id'];
    // $product_image = $_POST['product_image'];
    const formData = new FormData();
    formData.append("product_id", curFood.Product_ID);
    formData.append("product_image", curFood.Product_Image);
    fetch("https://api.checagocoffee.com/Functions/AdminDeleteProducts.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.text())
      .catch((error) => console.error(error));
  };

  const handleSave = (editedFood) => {
    setShowEditor(false);
    // Save edited food to backend
    const formData = new FormData();
    formData.append("product_id", editedFood.product.Product_ID);
    formData.append("shop_id", editedFood.product.Shop_ID);
    formData.append("product_name", editedFood.product.Product_Name);
    formData.append(
      "product_description",
      editedFood.product.Product_Description
    );
    formData.append("product_price", editedFood.product.Product_Price);
    formData.append("produce_sub_cat", editedFood.product.Sub_Cat_ID);
    formData.append("old_product_image", food.Product_Image);
    formData.append("new_product_image", editedFood.product.Product_Image);
    formData.append("is_top_food", editedFood.product.Product_Rank);
    fetch("https://api.checagocoffee.com/Functions/AdminEditProducts.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setFood(editedFood.product);
      })
      .catch((error) => console.error(error));
  };

  if (isDeleted) {
    return null;
  }

  return (
    <>
      {!showEditor && (
        <div
          key={index}
          className="d-flex justify-content-between align-items-center p-3 border rounded"
        >
          <label>{curFood.Product_Name}</label>
          <div>
            <label>{curFood.Product_Rank ? "Top Food" : null}</label>
            <button
              className="btn btn-outline-primary btn-sm mx-2"
              onClick={editFood}
            >
              Edit
            </button>
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={deleteFood}
            >
              Delete
            </button>
          </div>
        </div>
      )}
      {showEditor && (
        <div className="border rounded" ref={editorRef}>
          <ProductItemEditor
            onClose={() => setShowEditor(false)}
            food={curFood}
            onSave={handleSave}
          />
        </div>
      )}
    </>
  );
}
