const GOOGLE_PLACES_API_KEY = "AIzaSyB-kRr9bxeArHbI_8x1hYp3x37YNu6YZpc";

const LoadGoogleMapsScript = (callback) => {
  let existingScript = document.getElementById("googleMaps");
  if (!existingScript) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.id = "googleMaps";
    script.async = true;
    script.defer = true;

    if (script.readyState) {
      script.onreadystatechange = () => {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_PLACES_API_KEY}&libraries=places`;
    document.getElementsByTagName("head")[0].appendChild(script);
  } else {
    // If the script already exists, call the callback immediately
    callback();
  }
};

export default LoadGoogleMapsScript;
