import React, { useState, useEffect, useRef } from "react";
import LoadGoogleMapsScript from "../components/LoadGoogleMapsScript";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function UploadAndEditShop() {
  const [fetchedShops, setFetchedShops] = useState([]);

  const editShopRef = useRef(null);

  useEffect(() => {
    fetch("https://api.checagocoffee.com/Functions/AdminFetchShops.php", {
      method: "GET",
      mode: "cors",
    })
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
        handleFetchShops(json);
      })
      .catch((error) => console.error(error));
  }, []);

  // State to hold form data
  const [newShop, setNewShop] = useState(false);
  const [shopImage, setShopImage] = useState(null);
  const [shopsData, setShopsData] = useState({
    shopName: "",
    shopDescription: "",
    shopAddress: "",
    latitude: "",
    longitude: "",
    image: "",
    isActive: 1,
    shopWebsite: "",
  });
  const [selectedShop, setSelectedShop] = useState(null);
  const [selectedShopOldImage, setSelectedShopOldImage] = useState(null);

  const [shopHours, setShopHours] = useState([
    { day: "Monday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Tuesday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Wednesday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Thursday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Friday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Saturday", isOpen: false, openTime: "", closeTime: "" },
    { day: "Sunday", isOpen: false, openTime: "", closeTime: "" },
  ]);

  useEffect(() => {
    if (selectedShop && editShopRef.current) {
      editShopRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedShop]);

  let autoComplete;
  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    // debugger;
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      {
        types: ["address"],
        componentRestrictions: { country: ["us"] },
      }
    );

    autoComplete.addListener("place_changed", () => {
      console.log("place changed");
      handlePlaceSelect(updateQuery);
    });
  };

  const handlePlaceSelect = async (updateQuery) => {
    if (!autoComplete) {
      console.error("Autocomplete is not initialized");
      return;
    }
    var addressObject = await autoComplete.getPlace();
    if (!addressObject || !addressObject.geometry) {
      console.log(
        "No details available for input: '" +
          (addressObject ? addressObject.name : "unknown") +
          "'"
      );
      return;
    }

    const query = addressObject.formatted_address;
    if (!query) {
      console.error("Formatted address not found");
      return;
    }
    console.log(addressObject);
    updateQuery(query);
    console.log({ query });
    console.log("yoyo", addressObject, query);

    const latLng = {
      lat: addressObject?.geometry?.location?.lat(),
      lng: addressObject?.geometry?.location?.lng(),
    };

    let street = "";
    for (const component of addressObject.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "street_number": {
          street = `${component.long_name} ${street}`;
          break;
        }
        case "route": {
          street += component.long_name;
          break;
        }
        case "locality": {
          street += `, ${component.long_name}`;
          break;
        }
        case "administrative_area_level_1": {
          street += `, ${component.short_name}`;
          break;
        }
        default:
          break;
      }
    }

    setShopsData((prevState) => ({
      ...prevState,
      shopAddress: street,
      latitude: latLng.lat,
      longitude: latLng.lng,
    }));
    if (selectedShop != null) {
      setSelectedShop((prevState) => ({
        ...prevState,
        shopAddress: street,
        latitude: latLng.lat,
        longitude: latLng.lng,
      }));
    }
    console.log(street);
    console.log(latLng);
  };

  useEffect(() => {
    if (newShop || selectedShop) {
      LoadGoogleMapsScript(() => handleScriptLoad(setQuery, autoCompleteRef));
    }
  });

  // Function to handle form input changes
  const handleShopsChange = (e) => {
    const { name, value } = e.target;
    // console.log(name+ ": " + value);
    setShopsData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (selectedShop != null) {
      setSelectedShop((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  // Function to handle form submission
  const handleNewShopSubmit = (e) => {
    e.preventDefault();
    setNewShop(false);

    setShopsData({
      shopName: "",
      shopDescription: "",
      shopAddress: "",
      latitude: "",
      longitude: "",
      image: "",
      isActive: 1,
      shopWebsite: "",
    });

    // console.log(shopsData);
    // console.log(shopHours);

    // Create FormData object
    const formData = new FormData();

    // Append form fields to the FormData
    formData.append("shop_name", shopsData.shopName);
    formData.append("shop_description", shopsData.shopDescription);
    formData.append("shop_address", shopsData.shopAddress);
    formData.append("latitude", shopsData.latitude);
    formData.append("longitude", shopsData.longitude);
    formData.append("is_active", shopsData.isActive);
    formData.append("shop_website", shopsData.shopWebsite);
    formData.append("shop_image", shopImage);

    for (let i = 0; i < shopHours.length; i++) {
      formData.append(
        `${shopHours[i].day.toLowerCase()}_open_hour`,
        shopHours[i].openTime
      );
      formData.append(
        `${shopHours[i].day.toLowerCase()}_close_hour`,
        shopHours[i].closeTime
      );

      // console.log(`${shopHours[i].day.toLowerCase()}_open_hour`);
    }
    // Iterate over formData entries
    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    fetch("https://api.checagocoffee.com/Functions/AdminUploadNewShop.php", {
      method: "POST",
      mode: "cors",
      // No need to set Content-Type header, formData will do that automatically

      // headers: {
      //   "Content-type": "application/json; charset=UTF-8",
      // },
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        window.location.reload();
      })
      .catch((error) => console.error(error));

    setShopImage(null);
  };

  // Function to handle form submission
  const handleEditShopSubmit = (e) => {
    e.preventDefault();

    // console.log(selectedShopOldImage);
    // console.log(selectedShop.image);

    // console.log(selectedShop.isActive === "true");
    // console.log(selectedShop.isActive === "true" ? "1" : "0");

    const formData = new FormData();
    formData.append("shop_id", selectedShop.shopID);
    formData.append("shop_name", selectedShop.shopName);
    formData.append("shop_description", selectedShop.shopDescription);
    formData.append("shop_address", selectedShop.shopAddress);
    formData.append("latitude", selectedShop.latitude);
    formData.append("longitude", selectedShop.longitude);
    formData.append("is_active", selectedShop.isActive);
    formData.append("shop_website", selectedShop.shopWebsite);
    formData.append("old_shop_image", selectedShopOldImage);
    formData.append("new_shop_image", shopImage);

    for (let i = 0; i < shopHours.length; i++) {
      formData.append(
        `${selectedShop.shopHours[i].day.toLowerCase()}_open_hour`,
        selectedShop.shopHours[i].openTime
      );
      formData.append(
        `${selectedShop.shopHours[i].day.toLowerCase()}_close_hour`,
        selectedShop.shopHours[i].closeTime
      );
    }

    // Iterate over formData entries
    for (let [key, value] of formData.entries()) {
      console.log(`${key}: ${value}`);
    }

    fetch("https://api.checagocoffee.com/Functions/AdminEditShopDetails.php", {
      method: "POST",
      mode: "cors",
      // No need to set Content-Type header, formData will do that automatically

      // headers: {
      //   "Content-type": "application/json; charset=UTF-8",
      // },
      body: formData,
    })
      .then((response) => response.text())
      .then((json) => {
        window.location.reload();
      })
      .catch((error) => console.error(error));

    setSelectedShop(null);
    setShopImage(null);
    // TODO: Send shopsData to database
  };

  // const testingButton = (e) => {
  //   e.preventDefault();
  // };

  const handleFetchShops = (json) => {
    const workday = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const adjustedShops = Object.entries(json).map(([shopId, shopDetails]) => {
      if (shopDetails.hasOwnProperty("Shop_ID")) {
        shopDetails.shopID = shopDetails.Shop_ID;
        delete shopDetails.Shop_ID;
      }
      if (shopDetails.hasOwnProperty("Shop_Name")) {
        shopDetails.shopName = shopDetails.Shop_Name;
        delete shopDetails.Shop_Name;
      }
      if (shopDetails.hasOwnProperty("Shop_Description")) {
        shopDetails.shopDescription = shopDetails.Shop_Description;
        delete shopDetails.Shop_Description;
      }
      if (shopDetails.hasOwnProperty("Shop_Address")) {
        shopDetails.shopAddress = shopDetails.Shop_Address;
        delete shopDetails.Shop_Address;
      }
      if (shopDetails.hasOwnProperty("Latitude")) {
        shopDetails.latitude = shopDetails.Latitude;
        delete shopDetails.Latitude;
      }
      if (shopDetails.hasOwnProperty("Longitude")) {
        shopDetails.longitude = shopDetails.Longitude;
        delete shopDetails.Longitude;
      }
      if (shopDetails.hasOwnProperty("Image")) {
        shopDetails.image = shopDetails.Image;
        delete shopDetails.Image;
      }
      if (shopDetails.hasOwnProperty("Is_Active")) {
        shopDetails.isActive = shopDetails.Is_Active;
        delete shopDetails.Is_Active;
      }
      if (shopDetails.hasOwnProperty("Shop_Website")) {
        shopDetails.shopWebsite = shopDetails.Shop_Website;
        delete shopDetails.Shop_Website;
      }

      const adjustedShopHours = workday.map((day) => {
        // Find if the current day is in the shop's shopHours
        const shopHourForDay = shopDetails.shopHours.find(
          (shopHour) => shopHour.day === day
        );

        // console.log(shopHourForDay);

        if (shopHourForDay) {
          // If the day is found, it means the shop is open on this day
          return { ...shopHourForDay, isOpen: true };
        } else {
          // If the day is not found, the shop is closed on this day
          return { day: day, isOpen: false, openTime: "", closeTime: "" };
        }
      });

      return {
        ...shopDetails,
        shopHours: adjustedShopHours,
      };
    });
    setFetchedShops(adjustedShops);

    console.log(adjustedShops);
  };

  useEffect(() => {
    // console.log(fetchedShops);
  }, [fetchedShops]);

  // const fetchedShopsData = [
  //   {
  //     shopName: "Example Shop 1",
  //     shopDescription: "an example shop",
  //     shopAddress: "101 Example St",
  //     latitude: "0.0000",
  //     longitude: "0.0000",
  //     image: "/src/assets/images/shop1.jpg",
  //     isActive: true,
  //     shopHours: [
  //       { day: "Monday", isOpen: true, openTime: "09:00", closeTime: "20:00" },
  //       { day: "Tuesday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Wednesday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Thursday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Friday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Saturday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Sunday", isOpen: false, openTime: "", closeTime: "" },
  //     ],
  //   },
  //   {
  //     shopName: "Example Shop 2",
  //     shopDescription: "another example shop",
  //     shopAddress: "202 Example St",
  //     latitude: "0.0000",
  //     longitude: "0.0000",
  //     image: "/src/assets/images/shop2.jpg",
  //     isActive: true,
  //     shopHours: [
  //       { day: "Monday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Tuesday", isOpen: true, openTime: "10:00", closeTime: "18:00" },
  //       { day: "Wednesday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Thursday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Friday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Saturday", isOpen: false, openTime: "", closeTime: "" },
  //       { day: "Sunday", isOpen: false, openTime: "", closeTime: "" },
  //     ],
  //   },
  // ];

  const handleRowClick = (shop) => {
    setSelectedShop(shop);
    setSelectedShopOldImage(shop.image);
    setQuery(shop.shopAddress);

    // console.log(shop); // For demonstration, you might want to handle this data differently
  };

  const inactivateShop = (e, item) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("shop_id", item.shopID);
    fetch("https://api.checagocoffee.com/Functions/AdminInactivateShop.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        if (json.status === "success") {
          setFetchedShops((prevState) =>
            prevState.filter((shop) => shop.shopID !== item.shopID)
          );
          setSelectedShop(null);
          toast.success("Shop inactivated");
        } else {
          toast.error("Shop not inactivated");
        }
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="upload-edit-shop container-xl mt-5">
      <button
        type="button"
        className="btn btn-outline-primary mb-3"
        onClick={() => {
          setNewShop(true);

          setShopsData({
            shopName: "",
            shopDescription: "",
            shopAddress: "",
            latitude: "",
            longitude: "",
            image: "",
            shopWebsite: "",
            isActive: 1,
          });
        }}
      >
        Add New Shop
      </button>
      {newShop && (
        <form
          onSubmit={handleNewShopSubmit}
          encType="multipart/form-data"
          method="post"
          className="mb-3"
        >
          <div className="d-flex align-items-center justify-content-center mb-3">
            <button
              type="button"
              className="col-md-1 btn btn-outline-secondary"
              onClick={() => setNewShop(false)}
            >
              Back
            </button>
            <h3 className="col-md-4 mb-0 ms-3">Add New Shop</h3>
          </div>
          <div className="row mx-auto justify-content-center">
            <div className="shops-form col-md-5">
              <h4>Shops Info</h4>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="shopName"
                  placeholder="Shop Name"
                  value={shopsData.shopName}
                  onChange={handleShopsChange}
                />
              </div>
              <div className="mb-3">
                <textarea
                  className="form-control"
                  name="shopDescription"
                  placeholder="Shop Description"
                  value={shopsData.shopDescription}
                  onChange={handleShopsChange}
                  style={{ height: "auto", overflowY: "hidden" }}
                  onInput={(e) =>
                    (e.target.style.height = e.target.scrollHeight + "px")
                  }
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  ref={autoCompleteRef}
                  className="form-control"
                  name="shopAddress"
                  placeholder="Shop Address"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <input
                  type="text"
                  className="form-control"
                  name="shopWebsite"
                  placeholder="Shop Website"
                  value={shopsData.shopWebsite}
                  onChange={handleShopsChange}
                />
              </div>
              <div className="mb-3">
                <select
                  className="form-control"
                  name="isActive"
                  value={shopsData.isActive}
                  onChange={handleShopsChange}
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
              </div>
              <div className="mb-3">
                <input
                  type="file"
                  id="imageUpload"
                  className="form-control"
                  name="image"
                  accept="image/*"
                  onChange={(e) => {
                    setShopImage(e.target.files[0]);
                    // console.log(shopImage);
                  }}
                />
              </div>
            </div>

            {/* ============================================ */}

            <div className="shop-hours col-md-6">
              <h4>Shop Hours</h4>
              <div className="mb-3">
                {shopHours.map((workday, index) => (
                  <div key={workday.day} className="row align-items-center">
                    <div className="col-md-3">
                      <input
                        type="checkbox"
                        id={workday.day}
                        checked={workday.isOpen}
                        onChange={(e) => {
                          let updatedHours = [...shopHours];
                          updatedHours[index].isOpen = e.target.checked;
                          setShopHours(updatedHours);
                        }}
                      />
                      <label htmlFor={workday.day} className="ms-2">
                        {workday.day}
                      </label>
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        style={{ width: "110%" }}
                        value={workday.openTime}
                        onChange={(e) => {
                          const updatedHours = [...shopHours];
                          updatedHours[index].openTime = e.target.value;
                          setShopHours(updatedHours);
                        }}
                        disabled={!workday.isOpen}
                        pattern="(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)"
                        placeholder="HH:MM AM/PM"
                      />
                    </div>
                    <div className="col-md-3">
                      <input
                        type="text"
                        style={{ width: "110%" }}
                        value={workday.closeTime}
                        onChange={(e) => {
                          const updatedHours = [...shopHours];
                          updatedHours[index].closeTime = e.target.value;
                          setShopHours(updatedHours);
                        }}
                        disabled={!workday.isOpen}
                        pattern="(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)"
                        placeholder="HH:MM AM/PM"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="row col-6 mx-auto">
            <button type="submit" className="btn btn-outline-primary">
              Submit
            </button>
          </div>
        </form>
      )}

      {/* ============================================ */}

      {selectedShop && (
        <div ref={editShopRef} className="mt-3">
          <form onSubmit={handleEditShopSubmit} className="mb-3">
            <div className="d-flex align-items-center justify-content-center mb-3">
              <button
                type="button"
                className="col-md-1 btn btn-outline-secondary"
                onClick={() => setSelectedShop(null)}
              >
                Back
              </button>
              <h3 className="col-md-4 mb-0 ms-3">
                Edit Shop: {selectedShop.shopName}
              </h3>
            </div>
            <div className="row mx-auto justify-content-center">
              <div className="shops-form col-md-4">
                <h4>Shops Info</h4>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="shopName"
                    placeholder="Shop Name"
                    value={selectedShop.shopName}
                    onChange={handleShopsChange}
                  />
                </div>
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    name="shopDescription"
                    placeholder="Shop Description"
                    value={selectedShop.shopDescription}
                    onChange={handleShopsChange}
                    style={{ height: "auto", overflowY: "hidden" }}
                    onInput={(e) =>
                      (e.target.style.height = e.target.scrollHeight + "px")
                    }
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    ref={autoCompleteRef}
                    className="form-control"
                    name="shopAddress"
                    placeholder="Shop Address"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </div>
                {/* Other inputs for shopAddress, latitude, longitude */}

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    name="shopWebsite"
                    placeholder="Shop Website"
                    value={selectedShop.shopWebsite}
                    onChange={handleShopsChange}
                  />
                </div>
                <div className="mb-3">
                  <select
                    className="form-control"
                    name="isActive"
                    value={selectedShop.isActive.toString()}
                    onChange={handleShopsChange}
                  >
                    <option value={1}>Active</option>
                    <option value={0}>Inactive</option>
                  </select>
                </div>
                <div className="mb-3">
                  <input
                    type="file"
                    id="imageUpload"
                    className="form-control"
                    name="image"
                    accept="image/*"
                    onChange={(e) => {
                      // setSelectedShop((prevState) => ({
                      //   ...prevState,
                      //   [image]: e.target.files[0],
                      // }));

                      setShopImage(e.target.files[0]);
                      // console.log(e.target.files[0].name);
                      // console.log(selectedShopOldImage);
                    }}
                  />
                </div>
              </div>

              <div className="shop-hours col-md-6">
                <h4>Shop Hours</h4>
                <div className="mb-3">
                  {selectedShop.shopHours.map((workday, index) => (
                    <div
                      key={workday.day}
                      className="row  mx-auto align-items-center"
                    >
                      <div className="col-md-3">
                        <input
                          type="checkbox"
                          id={workday.day}
                          checked={workday.isOpen}
                          onChange={(e) => {
                            let updatedHours = [...selectedShop.shopHours];
                            updatedHours[index].isOpen = e.target.checked;
                            setShopHours(updatedHours);
                          }}
                        />
                        <label htmlFor={workday.day} className="ms-2">
                          {workday.day}
                        </label>
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          style={{ width: "110%" }}
                          value={workday.openTime}
                          onChange={(e) => {
                            const updatedHours = [...selectedShop.shopHours];
                            updatedHours[index].openTime = e.target.value;
                            setShopHours(updatedHours);
                          }}
                          disabled={!workday.isOpen}
                          pattern="(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)"
                          placeholder="HH:MM AM/PM"
                        />
                      </div>
                      <div className="col-md-3">
                        <input
                          type="text"
                          value={workday.closeTime}
                          style={{ width: "110%" }}
                          onChange={(e) => {
                            const updatedHours = [...selectedShop.shopHours];
                            updatedHours[index].closeTime = e.target.value;
                            setShopHours(updatedHours);
                          }}
                          disabled={!workday.isOpen}
                          pattern="(0[1-9]|1[0-2]):[0-5][0-9] (AM|PM)"
                          placeholder="HH:MM AM/PM"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="row col-6 mx-auto">
              <button type="submit" className="btn btn-outline-primary">
                Submit
              </button>
            </div>
          </form>
        </div>
      )}

      {/* ========================================================= */}

      {/* <div className="random">
        <button onClick={testingButton} className="btn btn-outline-primary">
          API-tester
        </button>
      </div> */}

      <div className="table-responsive">
        <h3>Shops List</h3>
        <table className="table">
          <thead>
            <tr>
              <th>Shop Name</th>
              <th>Shop Description</th>
              <th>Shop Address</th>
              <th>Image</th>
              <th>Shop Website</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {fetchedShops.map((item, index) => (
              <tr
                key={index}
                onClick={() => handleRowClick(item)}
                style={{ cursor: "pointer" }}
              >
                <td>{item.shopName}</td>
                <td>{item.shopDescription}</td>
                <td>{item.shopAddress}</td>
                <td>
                  <img
                    src={"https://api.checagocoffee.com/shops/" + item.image}
                    alt={item.shopName}
                    style={{ width: "100px", height: "auto" }}
                  />
                </td>
                <td>{item.shopWebsite}</td>
                <td>
                  <button
                    className="btn btn-outline-primary btn-sm"
                    onClick={(e) => {
                      e.preventDefault();
                      inactivateShop(e, item);
                    }}
                  >
                    Inactivate
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
