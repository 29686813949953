import "bootstrap/dist/css/bootstrap.min.css";
import "./Comment.css";
import React, { useState, useEffect } from "react";

export default function Users() {
    const [fetchedShops, setFetchedShops] = useState([]); // JSON Array, refer to API for structure
    const [shopFlag, setShopFlag] = useState(false);
    const [selectedShopDetails, setSelectedShopDetails] = useState(); // In the form of JSON object
    const [fetchedComments, setFetchedComments] = useState([]); // JSON Array, refer to APi for structure

    const [selectedCommentID, setSelectedCommentID] = useState("");
    const [selectedUsername, setSelectedUsername] = useState("");
    const [selectedComment, setSelectedComment] = useState("");
    const [selectedRating, setSelectedRating] = useState("");

    useEffect(() => {
        fetch("https://api.checagocoffee.com/Functions/AdminRetrieveShopWithRating.php", {
            method: "GET",
            mode: "cors",
        })
        .then((response) => response.json())
        .then((json) => {
        // console.log(json);
        setFetchedShops(json);
        })
        .catch((error) => console.error(error));
    }, []);

    const handleRowClick = (shop) => {
        console.log(shop); // For demonstration, you might want to handle this data differently
        setShopFlag(true); // This is set to true to hide the ShopList table
        setSelectedShopDetails(shop); // Save the selected shop to a variable so that it can be accessed by other functions

        const formData = new FormData();
        formData.append("shop_id", shop.Shop_ID);
        fetch("https://api.checagocoffee.com/Functions/AdminRetrieveShopComment.php", {
            method: "POST",
            mode: "cors",
            body: formData
        })
        .then((response) => response.json())
        .then((json) => {
            setFetchedComments(json);
        })
        .catch((error) => console.error(error));
    };

    const editClick = (comment) => {
        setSelectedCommentID(comment.Comment_ID);
        setSelectedUsername(comment.User_Name);
        setSelectedComment(comment.User_Comment);
        setSelectedRating(comment.User_Rating);
        
    }

    const deleteClick = (comment) => {
        const formData = new FormData();
        formData.append("comment_id", comment.Comment_ID);
        formData.append("shop_id", selectedShopDetails.Shop_ID);
        fetch("https://api.checagocoffee.com/Functions/AdminDeleteShopComment.php", {
            method: "POST",
            mode: "cors",
            body: formData
        })
        .then((response) => response.text())
        .then((json) => {
            console.log(json);

            setFetchedComments(
                (prev) => prev.filter((comments) => comments.Comment_ID !== comment.Comment_ID)
            );
        })
        .catch((error) => console.error(error));
    }

    const handleBackButton = () => {
        setShopFlag(false);

        // Clear out all of the fields
        setFetchedComments([]);
        setSelectedCommentID("");
        setSelectedUsername("");
        setSelectedComment("");
        setSelectedRating("");
    }

    const inputFormSubmit = (e) => {
        e.preventDefault();

        console.log("commentID: " + selectedCommentID);
        console.log("username: " + selectedUsername);
        console.log("comment: " + selectedComment);
        console.log("rating: " + selectedRating);

        const formData = new FormData();
        formData.append("comment_id", selectedCommentID);
        formData.append("user_comment", selectedComment);

        // Validate the selectedRating in case of typo,
        // forcing it in the range of 0-5
        if ( Number(selectedRating) > 5 ) {
            formData.append("user_rating", 5);
        } else if (Number(selectedRating) < 0) {
            formData.append("user_rating", 0);
        } else {
            formData.append("user_rating", selectedRating);
        }
        
        formData.append("shop_id", selectedShopDetails.Shop_ID);
        fetch("https://api.checagocoffee.com/Functions/AdminEditShopComment.php", {
            method: "POST",
            mode: "cors",
            body: formData
        })
        .then((response) => response.text())
        .then((json) => {
            console.log(json);
        })
        .catch((error) => console.error(error));
    }

    return(
        <>
        {!shopFlag && (
            <div className="comments_page mt-5">
                <div className="users-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: '50%', textAlign: 'center'}}>Shop Name</th>
                                <th style={{width: '50%', textAlign: 'center'}}>Shop Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedShops.map((item, index) => (
                                <tr key={index} onClick={() => handleRowClick(item)}>
                                    <td>{item.Shop_Name}</td>
                                    <td>{item.Shop_Rating}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )}

        {shopFlag && (
            <div className="comments_page mt-5">
                <button onClick={() => handleBackButton()} type="button" className="btn btn-primary mb-3">Back</button>

                <div className="d-flex align-items-center justify-content-center">
                    <div className="custom-input-form">
                        <form
                            onSubmit={inputFormSubmit}
                            encType="multipart/form-data"
                            method="post">
                            <input onChange={(e) => {
                                setSelectedUsername(e.target.value);
                            }} value={selectedUsername} className="form-control username_input" type="text" placeholder="Username" disabled={true}/>

                            <input onChange={(e) => {
                                setSelectedComment(e.target.value);
                            }} value={selectedComment} className="form-control email_input" type="text" placeholder="Comment"/>

                            <input onChange={(e) => {
                                setSelectedRating(e.target.value);
                            }} value={selectedRating} className="form-control email_input" type="text" placeholder="Rating"/>

                            <button type="submit" className="btn btn-outline-dark submit_button">Submit</button>
                        </form>
                    </div>
                </div>

                <div className="users-table">
                    <table className="table">
                        <thead>
                            <tr>
                                <th style={{width: '40%', textAlign: 'center'}}>Username</th>
                                <th style={{width: '40%', textAlign: 'center'}}>Comment</th>
                                <th style={{width: '10%', textAlign: 'center'}}>Rating</th>
                                <th style={{width: '10%'}}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fetchedComments.map((item, index) => (
                                <tr key={item.Comment_ID}>
                                    <td>{item.User_Name}</td>
                                    <td>{item.User_Comment}</td>
                                    <td>{item.User_Rating}</td>
                                    <td>
                                        <button 
                                            onClick={() => editClick(item)}
                                            className="btn btn-outline-dark submit_button">Edit</button>
                                        <button
                                            onClick={() => deleteClick(item)}
                                            className="btn btn-outline-danger">Delete</button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        )}
        </>
    );
}