import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function Events() {
  const [events, setEvents] = useState([]);
  const editEventRef = useRef(null);
  useEffect(() => {
    fetch("https://api.checagocoffee.com/Functions/RetrieveEvents.php", {
      method: "GET",
      mode: "cors",
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setEvents(json);
      })
      .catch((error) => console.error(error));
  }, []);
  // Event_Date
  // Event_End_Date
  // Event_Description
  // Event_Image
  // Event_Link
  // Event_Name
  // Event_ID

  // Add Event
  const [addEvent, setAddEvent] = useState(false);
  const [newEvent, setNewEvent] = useState({
    Event_Name: "",
    Event_Description: "",
    Event_Date: "",
    Event_End_Date: "",
    Event_Link: "",
    Event_Image: "",
  });
  const handleAddInputChange = (key, value) => {
    setNewEvent((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleAddEventSubmit = (e) => {
    e.preventDefault();

    let emptyFields = 0;
    let filledFields = 0;

    Object.values(newEvent).forEach((value) => {
      if (value === "" || value == null) {
        emptyFields++;
      } else {
        filledFields++;
      }
    });

    // If some fields are empty and some are filled, show an alert
    if (emptyFields > 0 && filledFields > 0) {
      toast.error(
        "Please ensure event has all fields filled or all fields empty."
      );
      return; // Exit the function after showing the alert
    }
    setAddEvent(false);
    const formData = new FormData();
    formData.append("event_name", newEvent.Event_Name);
    formData.append("event_description", newEvent.Event_Description);
    formData.append("event_date", newEvent.Event_Date);
    formData.append("event_end_date", newEvent.Event_End_Date);
    formData.append("event_link", newEvent.Event_Link);
    formData.append("event_image", newEvent.Event_Image);
    fetch("https://api.checagocoffee.com/Functions/AdminAddEvent.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setEvents((prev) => [...prev, json]);
        setNewEvent({
          Event_Name: "",
          Event_Description: "",
          Event_Date: "",
          Event_End_Date: "",
          Event_Link: "",
          Event_Image: "",
        });
      })
      .catch((error) => console.error(error));
  };

  // Edit Event
  const [showEditor, setShowEditor] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedEventOldImage, setSelectedEventOldImage] = useState(null);

  useEffect(() => {
    if (selectedEvent && editEventRef.current) {
      editEventRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [selectedEvent]);
  const handleEditorChange = (key, value) => {
    setSelectedEvent((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const editEvent = (e, item) => {
    e.preventDefault();
    setShowEditor(true);
    setSelectedEvent(item);
    setSelectedEventOldImage(item.Event_Image);
  };

  const handleEditProductSubmit = (e) => {
    e.preventDefault();
    setShowEditor(false);
    const formData = new FormData();
    formData.append("event_id", selectedEvent.Event_ID);
    formData.append("event_name", selectedEvent.Event_Name);
    formData.append("event_description", selectedEvent.Event_Description);
    formData.append("event_date", selectedEvent.Event_Date);
    formData.append("event_end_date", selectedEvent.Event_End_Date);
    formData.append("event_link", selectedEvent.Event_Link);
    formData.append("old_event_image", selectedEventOldImage);
    formData.append("new_event_image", selectedEvent.Event_Image);
    fetch("https://api.checagocoffee.com/Functions/AdminEditEvent.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setEvents((prev) =>
          prev.map((event) =>
            event.Event_ID === selectedEvent.Event_ID
              ? {
                  Event_Date: selectedEvent.Event_Date,
                  Event_End_Date: selectedEvent.Event_End_Date,
                  Event_Description: selectedEvent.Event_Description,
                  Event_ID: selectedEvent.Event_ID,
                  Event_Image: json.event_image,
                  Event_Link: selectedEvent.Event_Link,
                  Event_Name: selectedEvent.Event_Name,
                }
              : event
          )
        );
        setSelectedEvent(null);
      })
      .catch((error) => console.error(error));
  };

  // Delete Event
  const deleteEvent = (e, item) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("event_id", item.Event_ID);
    formData.append("event_image", item.Event_Image);
    fetch("https://api.checagocoffee.com/Functions/AdminDeleteEvent.php", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((json) => {
        console.log(json);
        setEvents((prev) =>
          prev.filter((event) => event.Event_ID !== item.Event_ID)
        );
      })
      .catch((error) => console.error(error));
  };

  return (
    <div className="container-xl mt-5">
      <h1>Events</h1>
      <button
        type="button"
        className="btn btn-outline-primary mb-3"
        onClick={(e) => {
          e.preventDefault();
          setAddEvent(true);
        }}
      >
        Add New Event
      </button>
      {addEvent && (
        <div className="border rounded col-12 col-sm-12 col-lg-8 mx-auto justify-content-center">
          <button
            type="button"
            className="mt-3 ms-4 btn-close"
            onClick={() => setAddEvent(false)}
          ></button>
          <form
            encType="multipart/form-data"
            method="post"
            className="my-3 ms-4"
          >
            <div className="products-form row mb-3">
              <div className="row">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    value={newEvent.Event_Name}
                    onChange={(e) =>
                      handleAddInputChange("Event_Name", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={newEvent.Event_Description}
                    onChange={(e) =>
                      handleAddInputChange("Event_Description", e.target.value)
                    }
                    style={{ height: "auto" }}
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Date"
                    value={newEvent.Event_Date}
                    onChange={(e) =>
                      handleAddInputChange("Event_Date", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event End Date"
                    value={newEvent.Event_End_Date}
                    onChange={(e) =>
                      handleAddInputChange("Event_End_Date", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Link"
                    value={newEvent.Event_Link}
                    onChange={(e) =>
                      handleAddInputChange("Event_Link", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="file"
                    className="form-control"
                    name="Event_Image"
                    accept="image/*"
                    onChange={(e) =>
                      handleAddInputChange("Event_Image", e.target.files[0])
                    }
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline-primary row col-8 mx-auto justify-content-center"
                onClick={handleAddEventSubmit}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )}

      {showEditor && (
        <div
          ref={editEventRef}
          id="form-edit-event"
          className="border rounded col-12 col-sm-12 col-lg-8  mx-auto justify-content-center"
        >
          <button
            type="button"
            className="mt-3 ms-4 btn-close"
            onClick={() => setShowEditor(false)}
          ></button>
          <form
            encType="multipart/form-data"
            method="post"
            className="my-3 ms-4"
          >
            <div className="products-form row mb-3">
              <div className="row">
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Name"
                    value={selectedEvent.Event_Name}
                    onChange={(e) =>
                      handleEditorChange("Event_Name", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    value={selectedEvent.Event_Description}
                    onChange={(e) =>
                      handleEditorChange("Event_Description", e.target.value)
                    }
                    style={{ height: "auto" }}
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Date"
                    value={selectedEvent.Event_Date}
                    onChange={(e) =>
                      handleEditorChange("Event_Date", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event End Date"
                    value={selectedEvent.Event_End_Date}
                    onChange={(e) =>
                      handleEditorChange("Event_End_Date", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Event Link"
                    value={selectedEvent.Event_Link}
                    onChange={(e) =>
                      handleEditorChange("Event_Link", e.target.value)
                    }
                  />
                </div>

                <div className="mb-3">
                  <input
                    type="file"
                    className="form-control"
                    name="Event_Image"
                    accept="image/*"
                    onChange={(e) =>
                      handleEditorChange("Event_Image", e.target.files[0])
                    }
                  />
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-outline-primary row col-8 mx-auto justify-content-center"
                onClick={handleEditProductSubmit}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      )}

      <table className="table">
        <thead>
          <tr>
            <th>Event Name</th>
            <th>Event Description</th>
            <th>Event Date</th>
            <th>Event End Date</th>
            <th>Event Link</th>
            <th>Event Image</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {events.map((item, index) => (
            <tr key={index} style={{ cursor: "pointer" }}>
              <td style={{ display: "none" }}>{item.Event_ID}</td>
              <td>{item.Event_Name}</td>
              <td>{item.Event_Description}</td>
              <td>{item.Event_Date}</td>
              <td>{item.Event_End_Date}</td>
              <td>
                <a
                  href={item.Event_Link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.Event_Link}
                </a>
              </td>
              <td>
                <img
                  src={"https://api.checagocoffee.com/news/" + item.Event_Image}
                  alt={item.Event_Name}
                  style={{ width: "100px", height: "auto" }}
                />
              </td>
              <td>
                <button
                  className="btn btn-outline-primary btn-sm"
                  onClick={(e) => {
                    e.preventDefault();
                    editEvent(e, item);
                  }}
                >
                  Edit
                </button>
                <button
                  className="btn btn-outline-danger btn-sm mt-3"
                  onClick={(e) => {
                    e.preventDefault();
                    deleteEvent(e, item);
                  }}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ToastContainer autoClose={3000} />
    </div>
  );
}
