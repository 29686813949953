import React from "react";
import Nav from "./Nav";
import { useAuth } from "../auth/AuthProvider";

export default function Header() {
  const { logout } = useAuth();
  return (
    <div className="bg-dark text-white">
      <h2 className="py-5 px-3">Side Menu</h2>
      <Nav />
      <h5
        className="text-white py-5 px-3"
        style={{
          textDecoration: "underline",
        }}
        onClick={logout}
      >
        Log out
      </h5>
    </div>
  );
}
